@import './theme/theme.scss';
@import './theme/variables.scss';
@import './theme/globalClasses.scss';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;

    * {
        box-sizing: border-box;
    }
}
